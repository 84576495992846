import { FieldArray, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import { Button, Col, Collapse, Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import RenderField from "./RenderField";
import {
  useCreateProject,
  useGetContentCategoryList,
  useGetSearchData,
} from "../../../../../queries/query-hooks/workspace/workspace-hook";
import useDebounce from "../../../../../components/custom-hooks/use-debounce";
import postService from "../../../../../api/services/post-service";
import {
  ADD_PROJECT,
  GET_CONTENT_SEARCH_GROUP_LIST,
  UPDATE_PROJECT,
} from "../../../../../api/endpoints/channel-endpoint";
import toast from "react-hot-toast";
import { useQueryClient } from "@tanstack/react-query";
import { GET_PROJECT_LIST } from "../../../../../queries/query-constants/workspace/workspace-constant";
import useRedirect from "../../../../../components/custom-hooks/use-redirect";
import useBackdrop from "../../../../../components/custom-hooks/use-backdrop";
import { debounce } from "lodash";
import { getPrincipleEntitiesForSelect } from "../../../../../common/utils/utils";

function SponsoredContentForm({
  project_type_code,
  project_group_id,
  project_type_id,
  projectDetails = [],
}) {
  const { redirectTo } = useRedirect();
  const queryClient = useQueryClient();
  const { showBackdrop, hideBackdrop } = useBackdrop();
  const [selectedPermissions, setSelectedPermissions] = useState([
    "show_read",
    "show_view",
    "show_CTA",
  ]);
  const [showPermissionError, setShowPermissionError] = useState(false);

  useEffect(() => {
    if (projectDetails.length > 0) {
      preFillProjectForm();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      showSelection: false,
      project_name: "",
      project_desc: "",
      principal_entity_id: "",
      project_start_date: "",
      project_end_date: "",
      project_contact_name: "",
      project_contact_email: "",
      project_contact_phone: "",
      project_po_ref: "",
      project_po_date: "",
      project_logo: "",
      project_group_id: project_group_id,
      ga_status: 0,
      project_type_id: project_type_id,
      project_impressions_target: 0,
      project_status: 1,
      project_owner: "",
      project_to_content: [
        {
          label: "",
          content_id: "",
          content_category: "",
          view_target: 0,
          read_target: 0,
          unique_reach_target: 0,
        },
      ],
    },
    validationSchema: Yup.object({
      project_name: Yup.string().required("Project Name is required"),
      project_desc: Yup.string().required("Project Description is required"),
      principal_entity_id: Yup.string().required(
        "Principal Entity is required"
      ),
      project_start_date: Yup.date().required("Start date is required"),
      project_end_date: Yup.date().required("End date is required"),
      project_contact_name: Yup.string().required(
        "Project contact person name is required"
      ),
      project_contact_email: Yup.string()
        .email()
        .required("Project contact person Email is required"),
      project_contact_phone: Yup.string()
        .matches(/^[6-9]\d{9}$/, {
          message: "Please enter valid number.",
          excludeEmptyString: false,
        })
        .required("Project contact person phone is required"),
      project_po_ref: Yup.string().required("Project PO Reference is required"),
      project_po_date: Yup.string().required("Project PO Date is required"),
      project_logo: Yup.string().required("Project Logo is required"),
      ga_status: Yup.number().required("GA Status is required"),
      project_impressions_target: Yup.number()
        .required("Project Impressions Target is required")
        .moreThan(0, "Please enter valid Impression target."),
      project_owner: Yup.string()
        .trim()
        .required("Project Owner name is required"),
      project_to_content: Yup.array().of(
        Yup.object().shape({
          label: Yup.string()
            .trim()
            .required("Content Category Name is required"),

          content_id: Yup.string().trim().required("Please select a content"),

          view_target: Yup.number()
            .required("Content View Target is required")
            .moreThan(0, "Please enter valid Impression target."),

          read_target: Yup.number()
            .required("Content Read Target is required")
            .moreThan(0, "Please enter valid Reach target."),

          unique_reach_target: Yup.number()
            .required("Content Reach Target is required")
            .moreThan(0, "Please enter valid Reach target."),

          content_category: Yup.string()
            .trim()
            .required("Content Category is required"),
        })
      ),
    }),
    onSubmit: async (values) => {
      setShowPermissionError(false);
      showBackdrop();

      const payload = { ...values, project_attributes: [] };
      selectedPermissions.forEach((permission) => {
        payload.project_attributes.push({
          key: permission,
          value: "true",
        });
      });

      //check if atleast view or read permission is selected
      if (
        !selectedPermissions.includes("show_view") &&
        !selectedPermissions.includes("show_read") &&
        !selectedPermissions.includes("show_CTA")
      ) {
        setShowPermissionError(true);
        hideBackdrop();
        return;
      }

      let response;
      if (projectDetails.length > 0) {
        response = await postService(UPDATE_PROJECT, payload);
      } else {
        response = await postService(ADD_PROJECT, payload);
      }

      if (!response.data?.success) {
        toast.error("Something went wrong");
        hideBackdrop();
      } else {
        queryClient.invalidateQueries({
          queryKey: [GET_PROJECT_LIST],
        });

        if (projectDetails.length > 0) {
          toast.success("Project updated successfully");
        } else {
          toast.success("Project created successfully");
        }

        hideBackdrop();
        redirectTo("workspace/clirnet");
      }
    },
  });
  const {
    values,
    touched,
    errors,
    handleSubmit,
    handleChange,
    setFieldValue,
    setValues,
  } = formik;
  const { data: createProjectData, isLoading: principalEntitiesLoading } =
    useCreateProject();
  const fields = [
    {
      id: 1,
      label: "Project Name",
      field_name: "project_name",
      type: "text",
      placeholder: "Enter Project Name",
      required: true,
    },
    {
      id: 2,
      label: "Project Description",
      field_name: "project_desc",
      type: "textarea",
      placeholder: "Enter Project Description",
      required: true,
    },
    {
      id: 3,
      label: "Principal Entity",
      field_name: "principal_entity_id",
      type: "select",
      options: getPrincipleEntitiesForSelect(
        createProjectData?.principal_entities || []
      ),
      required: true,
      optionLabelKey: "principal_entity_name",
      optionValueKey: "principal_entity_id",
    },
    {
      id: 4,
      label: "GA Status",
      field_name: "ga_status",
      type: "switch",
      required: false,
    },
    {
      id: 4,
      label: "Start Date",
      field_name: "project_start_date",
      type: "date",
      required: true,
    },
    {
      id: 5,
      label: "End Date",
      field_name: "project_end_date",
      type: "date",
      required: true,
    },
    {
      id: 6,
      label: "Project Owner Name",
      field_name: "project_owner",
      type: "text",
      placeholder: "Enter Project Owner",
      required: true,
    },
    {
      id: 7,
      label: "Project Contant Person Name",
      field_name: "project_contact_name",
      type: "text",
      placeholder: "Enter Owner Contact",
      required: true,
    },
    {
      id: 8,
      label: "Project Contant Person Email",
      field_name: "project_contact_email",
      type: "email",
      placeholder: "Enter Owner Email",
      required: true,
    },
    {
      id: 9,
      label: "Project Contant Person Phone",
      field_name: "project_contact_phone",
      type: "number",
      placeholder: "Enter Owner Phone",
      required: true,
    },
    {
      id: 11,
      label: "Purchase Order Reference",
      field_name: "project_po_ref",
      type: "text",
      placeholder: "Enter Purchase Order Reference",
      required: true,
    },
    {
      id: 12,
      label: "Purchase Order Date",
      field_name: "project_po_date",
      type: "date",
      placeholder: "Enter Purchase Order Date",
      required: true,
    },
    {
      id: 13,
      label: "Project Logo",
      field_name: "project_logo",
      type: "file",
      required: true,
    },
    {
      id: 14,
      label: "Project Impressions Target",
      field_name: "project_impressions_target",
      type: "number",
      required: true,
    },
  ];
  const [searchText, setSearchText] = useState({});

  const { data: contentCategories, isLoading: isContentCategoriesLoading } =
    useGetContentCategoryList();

  const _loadSuggestions = (
    inputValue,
    selectedCategory,
    content_category_id
  ) => {
    const searchValue = isNaN(inputValue) ? inputValue : Number(inputValue);
    return new Promise(async (resolve) => {
      const response = await postService(GET_CONTENT_SEARCH_GROUP_LIST, {
        slug: selectedCategory,
        search_key: searchValue,
        content_category_id: content_category_id,
      });

      if (!response.isError) {
        const searchData = [];
        response?.data?.data &&
          response?.data?.data.map((elem) => {
            elem.content_data.forEach((elem) => {
              searchData.push({
                value: elem.id,
                label: `${elem.content_old_id} - ${elem.title}`,
              });
            });
          });

        resolve(searchData);
      } else {
        toast.error("Error fetching data!");
      }
    });
  };

  function preFillProjectForm() {
    const projectImpressionsTarget =
      projectDetails?.at(0)?.project_target?.impressions_target || 0;

    const projectToContent = projectDetails
      ?.at(0)
      ?.project_contents?.map((elem) => {
        const content = elem.fetch_with_contents?.at(0);
        return {
          label: content?.content_title,
          content_id: content?.content_id,
          content_category: content?.content_category?.content_category_code,
          read_target: content?.content_target?.read_target,
          view_target: content?.content_target?.view_target,
          unique_reach_target: content?.content_target?.unique_reach_target,
        };
      });

    setValues({
      ...values,
      ...projectDetails?.at(0),
      project_impressions_target: projectImpressionsTarget,
      project_to_content: projectToContent || [],
    });
  }

  const tabOptions = [
    {
      item: "show_view",
      tab: "View",
    },
    {
      item: "show_read",
      tab: "Read",
    },
    {
      item: "show_CTA",
      tab: "CTA",
    },
  ];
  return (
    <Form onSubmit={handleSubmit}>
      {fields &&
        fields.map((field) => (
          <RenderField
            {...field}
            handleChange={handleChange}
            values={values}
            touched={touched}
            errors={errors}
            key={field.id}
            setFieldValue={setFieldValue}
          />
        ))}

      <Row className="align-items-md-center">
        <Col
          md={4}
          className="mb-md-3 mb-md-0 justify-content-center align-items-center"
        >
          <label className="form-label mb-md-3">Select Permissions *</label>
          {showPermissionError && (
            <>
              <div className="is-invalid"></div>
              <Form.Control.Feedback type="invalid">
                Please select atleast one permission (view/Read)
              </Form.Control.Feedback>
            </>
          )}
        </Col>

        <Col md={8}>
          <Row>
            <Col className="d-flex gap-md-4 gap-3 flex-wrap justify-content-md-end mb-3">
              {tabOptions?.map(({ item, tab }) => (
                <div key={tab} className="form-check m-0">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    name={"select_permissions"}
                    id={item + "check"}
                    checked={selectedPermissions.includes(item)}
                    onChange={() => {
                      if (selectedPermissions.includes(item)) {
                        setSelectedPermissions(
                          selectedPermissions.filter((value) => value !== item)
                        );
                      } else {
                        setSelectedPermissions((prev) => [...prev, item]);
                      }
                    }}
                  />
                  <label
                    class="form-check-label text-capitalize pl-2"
                    for={item + "check"}
                  >
                    {tab}
                  </label>
                </div>
              ))}
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Form.Check
        type="switch"
        id="custom-switch"
        label="Add Content Groups"
        onChange={() => setFieldValue("showSelection", !values?.showSelection)}
        className="mb-3"
      />
      <Collapse in={values?.showSelection}> */}

      <div>
        <FormikProvider value={formik}>
          <FieldArray name="project_to_content">
            {(prop) => (
              <>
                {values.project_to_content.map((item, index) => (
                  <Row key={index}>
                    <Col lg={6}>
                      <RenderField
                        type="select"
                        field_name={`project_to_content[${index}].content_category`}
                        isLoading={isContentCategoriesLoading}
                        values={values}
                        touched={touched}
                        errors={errors}
                        options={contentCategories?.map((item) => {
                          return {
                            label: item?.content_category_name,
                            value: item?.content_category_code,
                          };
                        })}
                        handleChange={(e) => {
                          handleChange(e);

                          setFieldValue(
                            `project_to_content[${index}].content_id`,
                            " "
                          );
                          setFieldValue(
                            `project_to_content[${index}].label`,
                            " "
                          );
                        }}
                        label={"Content Category"}
                        required={true}
                      />
                    </Col>

                    <Col lg={6} className="mb-3">
                      <RenderField
                        required={true}
                        type="searchable"
                        placeholder="Select Content"
                        label={`Select Content *`}
                        field_name={`project_to_content[${index}].content_id`}
                        searchText={searchText}
                        loadOptions={debounce((inputText, callback) => {
                          setSearchText(inputText);
                          _loadSuggestions(
                            inputText,
                            values?.project_to_content[index]
                              ?.content_category || "",

                            contentCategories?.find(
                              (item) =>
                                item?.content_category_code ===
                                values?.project_to_content[index]
                                  ?.content_category
                            )?.content_category_id
                          )
                            .then((options) => {
                              console.log("fetched data", options);
                              callback(options);
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }, 300)}
                        handleChange={(val) => {
                          if (val) {
                            setFieldValue(
                              `project_to_content[${index}].content_id`,
                              val?.value || ""
                            );
                            setFieldValue(
                              `project_to_content[${index}].label`,
                              val?.label || ""
                            );
                          } else {
                            setFieldValue(
                              `project_to_content[${index}].content_id`,
                              ""
                            );
                            setFieldValue(
                              `project_to_content[${index}].label`,
                              ""
                            );
                          }
                        }}
                        selected={
                          values.project_to_content[index]?.label
                            ? {
                                value:
                                  values.project_to_content[index]?.content_id,
                                label: values.project_to_content[index]?.label,
                              }
                            : ""
                        }
                        values={values}
                        touched={touched}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        disabled={
                          values[`project_to_content`][index]
                            ?.content_category == ""
                            ? true
                            : false
                        }
                      />
                    </Col>

                    <Col lg={3}>
                      <RenderField
                        type="number"
                        field_name={`project_to_content[${index}].read_target`}
                        placeholder="Read Target"
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        label={"Read Target"}
                        required={true}
                      />
                    </Col>
                    <Col lg={3}>
                      <RenderField
                        type="number"
                        field_name={`project_to_content[${index}].view_target`}
                        placeholder="View Target"
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        label={"View Target"}
                        required={true}
                      />
                    </Col>
                    <Col lg={3}>
                      <RenderField
                        type="number"
                        field_name={`project_to_content[${index}].unique_reach_target`}
                        placeholder="Reach Target"
                        values={values}
                        touched={touched}
                        errors={errors}
                        handleChange={handleChange}
                        label={"Reach Target"}
                        required={true}
                      />
                    </Col>
                    <Col
                      lg={3}
                      className="d-flex justify-content-center align-items-center"
                    >
                      <Button
                        variant="outline-primary"
                        className="border-dashed me-3"
                        onClick={() => prop?.remove(index)}
                        disabled={index == 0 ? true : false}
                      >
                        -
                      </Button>
                      <Button
                        variant="outline-primary"
                        className="border-dashed"
                        onClick={() =>
                          prop?.push({
                            label: "",
                            content_id: "",
                            view_target: 0,
                            read_target: 0,
                            unique_reach_target: 0,
                            content_category: "",
                          })
                        }
                        disabled={
                          values[`project_to_content`][index]?.label == ""
                            ? true
                            : false
                        }
                      >
                        +
                      </Button>
                    </Col>
                  </Row>
                ))}
              </>
            )}
          </FieldArray>
        </FormikProvider>
      </div>
      {/* </Collapse> */}

      <Button type="submit" className="me-4">{`${
        projectDetails.length > 0 ? "Update" : "Submit"
      }`}</Button>

      <Button
        variant="secondary"
        onClick={() => redirectTo("workspace/clirnet")}
      >
        Cancel
      </Button>
    </Form>
  );
}

export default SponsoredContentForm;
